// extracted by mini-css-extract-plugin
export var mt0 = "DesignWithUs-module--mt-0--YYOrn";
export var pt0 = "DesignWithUs-module--pt-0--3XXh5";
export var mb0 = "DesignWithUs-module--mb-0--1mbwV";
export var pb0 = "DesignWithUs-module--pb-0--1iHVU";
export var ml0 = "DesignWithUs-module--ml-0--271O4";
export var pl0 = "DesignWithUs-module--pl-0--3JSCB";
export var mr0 = "DesignWithUs-module--mr-0--28Jw_";
export var pr0 = "DesignWithUs-module--pr-0--3bOpw";
export var mt5 = "DesignWithUs-module--mt-5--3q7ox";
export var pt5 = "DesignWithUs-module--pt-5--NbR45";
export var mb5 = "DesignWithUs-module--mb-5--3kbmJ";
export var pb5 = "DesignWithUs-module--pb-5--18GSJ";
export var ml5 = "DesignWithUs-module--ml-5--2aPOg";
export var pl5 = "DesignWithUs-module--pl-5--321fC";
export var mr5 = "DesignWithUs-module--mr-5--1KSJ5";
export var pr5 = "DesignWithUs-module--pr-5--yTB5M";
export var mt10 = "DesignWithUs-module--mt-10--1EeG7";
export var pt10 = "DesignWithUs-module--pt-10--2hqsp";
export var mb10 = "DesignWithUs-module--mb-10--1oErz";
export var pb10 = "DesignWithUs-module--pb-10--2pGLX";
export var ml10 = "DesignWithUs-module--ml-10--3eUOl";
export var pl10 = "DesignWithUs-module--pl-10--1_HkE";
export var mr10 = "DesignWithUs-module--mr-10--1KxX5";
export var pr10 = "DesignWithUs-module--pr-10--2yPOW";
export var mt15 = "DesignWithUs-module--mt-15--2Tkbx";
export var pt15 = "DesignWithUs-module--pt-15--3xQoM";
export var mb15 = "DesignWithUs-module--mb-15--1K3k_";
export var pb15 = "DesignWithUs-module--pb-15--1LBsa";
export var ml15 = "DesignWithUs-module--ml-15--2JESm";
export var pl15 = "DesignWithUs-module--pl-15--3gVw0";
export var mr15 = "DesignWithUs-module--mr-15--yY8ZF";
export var pr15 = "DesignWithUs-module--pr-15--3Zdc3";
export var mt20 = "DesignWithUs-module--mt-20--15zlk";
export var pt20 = "DesignWithUs-module--pt-20--15t5d";
export var mb20 = "DesignWithUs-module--mb-20--2-Ygz";
export var pb20 = "DesignWithUs-module--pb-20--z6wsa";
export var ml20 = "DesignWithUs-module--ml-20--2XJSN";
export var pl20 = "DesignWithUs-module--pl-20--1M4rw";
export var mr20 = "DesignWithUs-module--mr-20--3kxY5";
export var pr20 = "DesignWithUs-module--pr-20--28HuD";
export var mt25 = "DesignWithUs-module--mt-25--21b1T";
export var pt25 = "DesignWithUs-module--pt-25--kjMWC";
export var mb25 = "DesignWithUs-module--mb-25--2aicO";
export var pb25 = "DesignWithUs-module--pb-25--1-Cpx";
export var ml25 = "DesignWithUs-module--ml-25--1Dym9";
export var pl25 = "DesignWithUs-module--pl-25--2mYpY";
export var mr25 = "DesignWithUs-module--mr-25--3u1lo";
export var pr25 = "DesignWithUs-module--pr-25--33yap";
export var mt30 = "DesignWithUs-module--mt-30--2KJ4s";
export var pt30 = "DesignWithUs-module--pt-30--3b06F";
export var mb30 = "DesignWithUs-module--mb-30--2VQnp";
export var pb30 = "DesignWithUs-module--pb-30--26YVS";
export var ml30 = "DesignWithUs-module--ml-30--3MhoX";
export var pl30 = "DesignWithUs-module--pl-30--3oKj6";
export var mr30 = "DesignWithUs-module--mr-30--1PPVd";
export var pr30 = "DesignWithUs-module--pr-30--5lxkx";
export var mt35 = "DesignWithUs-module--mt-35--3_Wse";
export var pt35 = "DesignWithUs-module--pt-35--2z6hK";
export var mb35 = "DesignWithUs-module--mb-35--28Xx4";
export var pb35 = "DesignWithUs-module--pb-35--36tdn";
export var ml35 = "DesignWithUs-module--ml-35--1qApR";
export var pl35 = "DesignWithUs-module--pl-35--20Gj-";
export var mr35 = "DesignWithUs-module--mr-35--3QNEW";
export var pr35 = "DesignWithUs-module--pr-35--9-oFa";
export var mt40 = "DesignWithUs-module--mt-40--15SSx";
export var pt40 = "DesignWithUs-module--pt-40--1uw_j";
export var mb40 = "DesignWithUs-module--mb-40--11_3A";
export var pb40 = "DesignWithUs-module--pb-40--XJcde";
export var ml40 = "DesignWithUs-module--ml-40--2EScy";
export var pl40 = "DesignWithUs-module--pl-40--y1D0r";
export var mr40 = "DesignWithUs-module--mr-40--cqhYy";
export var pr40 = "DesignWithUs-module--pr-40--17Iy4";
export var mt45 = "DesignWithUs-module--mt-45--aNzqQ";
export var pt45 = "DesignWithUs-module--pt-45--TV3iw";
export var mb45 = "DesignWithUs-module--mb-45--IoFkW";
export var pb45 = "DesignWithUs-module--pb-45--2Nu4w";
export var ml45 = "DesignWithUs-module--ml-45--1iLpD";
export var pl45 = "DesignWithUs-module--pl-45--2ElKf";
export var mr45 = "DesignWithUs-module--mr-45--390rv";
export var pr45 = "DesignWithUs-module--pr-45--2l1lR";
export var mt50 = "DesignWithUs-module--mt-50--3ddys";
export var pt50 = "DesignWithUs-module--pt-50--3Wydk";
export var mb50 = "DesignWithUs-module--mb-50--3RvNy";
export var pb50 = "DesignWithUs-module--pb-50--2RE6R";
export var ml50 = "DesignWithUs-module--ml-50--lu9oc";
export var pl50 = "DesignWithUs-module--pl-50--NaXi1";
export var mr50 = "DesignWithUs-module--mr-50--1vlhB";
export var pr50 = "DesignWithUs-module--pr-50--1gxqM";
export var mt75 = "DesignWithUs-module--mt-75--3AUVY";
export var pt75 = "DesignWithUs-module--pt-75--3Elu8";
export var mb75 = "DesignWithUs-module--mb-75--TpwTA";
export var pb75 = "DesignWithUs-module--pb-75--3AxqF";
export var ml75 = "DesignWithUs-module--ml-75--3EBpd";
export var pl75 = "DesignWithUs-module--pl-75--zq0_i";
export var mr75 = "DesignWithUs-module--mr-75--2nlT0";
export var pr75 = "DesignWithUs-module--pr-75--2jZgo";
export var mt100 = "DesignWithUs-module--mt-100--3p2Um";
export var pt100 = "DesignWithUs-module--pt-100--1w_bO";
export var mb100 = "DesignWithUs-module--mb-100--2Rx2r";
export var pb100 = "DesignWithUs-module--pb-100---B1RS";
export var ml100 = "DesignWithUs-module--ml-100--u3keO";
export var pl100 = "DesignWithUs-module--pl-100--2SXeL";
export var mr100 = "DesignWithUs-module--mr-100--1UMqs";
export var pr100 = "DesignWithUs-module--pr-100--1l2Or";
export var zIndex1 = "DesignWithUs-module--z-index-1--3heVD";
export var zIndex2 = "DesignWithUs-module--z-index-2--2sAJL";
export var zIndex3 = "DesignWithUs-module--z-index-3--2OHUx";
export var zIndex4 = "DesignWithUs-module--z-index-4--2M8Z1";
export var zIndex5 = "DesignWithUs-module--z-index-5--3mT2S";
export var zIndex6 = "DesignWithUs-module--z-index-6--12rnF";
export var zIndex7 = "DesignWithUs-module--z-index-7--1RLFy";
export var zIndex8 = "DesignWithUs-module--z-index-8--1jVaE";
export var zIndex9 = "DesignWithUs-module--z-index-9--3erI0";
export var zIndex10 = "DesignWithUs-module--z-index-10--2YHWN";
export var zIndex20 = "DesignWithUs-module--z-index-20--3PKdj";
export var zIndex30 = "DesignWithUs-module--z-index-30--2fMbe";
export var zIndex40 = "DesignWithUs-module--z-index-40--4TBXC";
export var zIndex50 = "DesignWithUs-module--z-index-50--2bS9E";
export var zIndex60 = "DesignWithUs-module--z-index-60--3-aMi";
export var zIndex70 = "DesignWithUs-module--z-index-70--1Mr3b";
export var zIndex80 = "DesignWithUs-module--z-index-80--1KlH4";
export var zIndex90 = "DesignWithUs-module--z-index-90--3U2E6";
export var zIndex100 = "DesignWithUs-module--z-index-100--2cMxJ";
export var link = "DesignWithUs-module--link--2tO0I";
export var uppercase = "DesignWithUs-module--uppercase--2obon";
export var more = "DesignWithUs-module--more--e__X0";
export var button = "DesignWithUs-module--button--3HaxC";
export var send = "DesignWithUs-module--send--E_mNn";
export var sm = "DesignWithUs-module--sm--1p2ZH";
export var designWithUsWrap = "DesignWithUs-module--designWithUsWrap--3Dksn";
export var sectionShadowTitle = "DesignWithUs-module--sectionShadowTitle--10V82";
export var contentWrap = "DesignWithUs-module--contentWrap--3ptnD";
export var img = "DesignWithUs-module--img--1U_QT";
export var descriptionWrap = "DesignWithUs-module--descriptionWrap--PQZyz";
export var descriptionTitle = "DesignWithUs-module--descriptionTitle--2NTkw";
export var description = "DesignWithUs-module--description--2HCK_";