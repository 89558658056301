// extracted by mini-css-extract-plugin
export var mt0 = "index-module--mt-0--3Ufo5";
export var pt0 = "index-module--pt-0--p9P4E";
export var mb0 = "index-module--mb-0--3DNmC";
export var pb0 = "index-module--pb-0--2O62W";
export var ml0 = "index-module--ml-0--24G4i";
export var pl0 = "index-module--pl-0--1sWaO";
export var mr0 = "index-module--mr-0--hyGzw";
export var pr0 = "index-module--pr-0--1x6Mm";
export var mt5 = "index-module--mt-5--3zEzc";
export var pt5 = "index-module--pt-5--mJB5j";
export var mb5 = "index-module--mb-5--2gmfe";
export var pb5 = "index-module--pb-5--XDGck";
export var ml5 = "index-module--ml-5--7JqwH";
export var pl5 = "index-module--pl-5--2Zt6c";
export var mr5 = "index-module--mr-5--dh69z";
export var pr5 = "index-module--pr-5--3yl5E";
export var mt10 = "index-module--mt-10--1vmod";
export var pt10 = "index-module--pt-10--31AL_";
export var mb10 = "index-module--mb-10--3PYbe";
export var pb10 = "index-module--pb-10--1_GXR";
export var ml10 = "index-module--ml-10--ofF9G";
export var pl10 = "index-module--pl-10--JuCPh";
export var mr10 = "index-module--mr-10--3Kchy";
export var pr10 = "index-module--pr-10--i_M33";
export var mt15 = "index-module--mt-15--2K0uL";
export var pt15 = "index-module--pt-15--3xnCg";
export var mb15 = "index-module--mb-15--3tuJa";
export var pb15 = "index-module--pb-15--2bOHg";
export var ml15 = "index-module--ml-15--26v5r";
export var pl15 = "index-module--pl-15--1RUOE";
export var mr15 = "index-module--mr-15--A508o";
export var pr15 = "index-module--pr-15--15KX1";
export var mt20 = "index-module--mt-20--1zPA0";
export var pt20 = "index-module--pt-20--Jk0TS";
export var mb20 = "index-module--mb-20--2h4jp";
export var pb20 = "index-module--pb-20--3sbTQ";
export var ml20 = "index-module--ml-20--2v92E";
export var pl20 = "index-module--pl-20--1U5UG";
export var mr20 = "index-module--mr-20--3X7hZ";
export var pr20 = "index-module--pr-20--2l930";
export var mt25 = "index-module--mt-25--2av2-";
export var pt25 = "index-module--pt-25--EQHXR";
export var mb25 = "index-module--mb-25--1CpQs";
export var pb25 = "index-module--pb-25--52XMv";
export var ml25 = "index-module--ml-25--CfisH";
export var pl25 = "index-module--pl-25--12iHH";
export var mr25 = "index-module--mr-25--1gKNG";
export var pr25 = "index-module--pr-25--2EW9L";
export var mt30 = "index-module--mt-30--3jjPm";
export var pt30 = "index-module--pt-30--1ypZN";
export var mb30 = "index-module--mb-30--cb9Dk";
export var pb30 = "index-module--pb-30--1_3Sr";
export var ml30 = "index-module--ml-30--38f0I";
export var pl30 = "index-module--pl-30--km5Et";
export var mr30 = "index-module--mr-30--3g13K";
export var pr30 = "index-module--pr-30--6oOA9";
export var mt35 = "index-module--mt-35--3RoxH";
export var pt35 = "index-module--pt-35--EtN-3";
export var mb35 = "index-module--mb-35--1mx5f";
export var pb35 = "index-module--pb-35--3DLJa";
export var ml35 = "index-module--ml-35--1_BUB";
export var pl35 = "index-module--pl-35--3ZNED";
export var mr35 = "index-module--mr-35--TQVel";
export var pr35 = "index-module--pr-35--RI0rW";
export var mt40 = "index-module--mt-40--rFHmr";
export var pt40 = "index-module--pt-40--1d1Pr";
export var mb40 = "index-module--mb-40--3ody2";
export var pb40 = "index-module--pb-40--UGYH-";
export var ml40 = "index-module--ml-40--2ub1O";
export var pl40 = "index-module--pl-40--3nyLY";
export var mr40 = "index-module--mr-40--LmPIj";
export var pr40 = "index-module--pr-40--1kuge";
export var mt45 = "index-module--mt-45--tlmAL";
export var pt45 = "index-module--pt-45--2KVFn";
export var mb45 = "index-module--mb-45--2NP3G";
export var pb45 = "index-module--pb-45--3xsQ2";
export var ml45 = "index-module--ml-45--30OXa";
export var pl45 = "index-module--pl-45--2NJa4";
export var mr45 = "index-module--mr-45--2vFOt";
export var pr45 = "index-module--pr-45--1wT_B";
export var mt50 = "index-module--mt-50--VvGJE";
export var pt50 = "index-module--pt-50--lmTO7";
export var mb50 = "index-module--mb-50--1XSI9";
export var pb50 = "index-module--pb-50--3g4dt";
export var ml50 = "index-module--ml-50--1jigF";
export var pl50 = "index-module--pl-50--2hTXF";
export var mr50 = "index-module--mr-50--3xMu0";
export var pr50 = "index-module--pr-50--3j4wh";
export var mt75 = "index-module--mt-75--qro9v";
export var pt75 = "index-module--pt-75--1y8mv";
export var mb75 = "index-module--mb-75--EjA4C";
export var pb75 = "index-module--pb-75--2FOXZ";
export var ml75 = "index-module--ml-75--6_aw8";
export var pl75 = "index-module--pl-75--1DmaG";
export var mr75 = "index-module--mr-75--3tjpZ";
export var pr75 = "index-module--pr-75--GAHCI";
export var mt100 = "index-module--mt-100--3HynS";
export var pt100 = "index-module--pt-100--2kpSN";
export var mb100 = "index-module--mb-100--A9abm";
export var pb100 = "index-module--pb-100--_embC";
export var ml100 = "index-module--ml-100--35jA-";
export var pl100 = "index-module--pl-100--3ktKR";
export var mr100 = "index-module--mr-100--3Y__Z";
export var pr100 = "index-module--pr-100--wnVYr";
export var zIndex1 = "index-module--z-index-1--3IBZ4";
export var zIndex2 = "index-module--z-index-2--DJ0A_";
export var zIndex3 = "index-module--z-index-3--j0uJo";
export var zIndex4 = "index-module--z-index-4--2uB_n";
export var zIndex5 = "index-module--z-index-5--3Fwi_";
export var zIndex6 = "index-module--z-index-6--1zQfx";
export var zIndex7 = "index-module--z-index-7--cFKe9";
export var zIndex8 = "index-module--z-index-8--hT7pz";
export var zIndex9 = "index-module--z-index-9--3xfiC";
export var zIndex10 = "index-module--z-index-10--S0a7H";
export var zIndex20 = "index-module--z-index-20--WLPYO";
export var zIndex30 = "index-module--z-index-30--1emr1";
export var zIndex40 = "index-module--z-index-40--3ngxp";
export var zIndex50 = "index-module--z-index-50--uUqWA";
export var zIndex60 = "index-module--z-index-60--1zcLO";
export var zIndex70 = "index-module--z-index-70--1BdTA";
export var zIndex80 = "index-module--z-index-80--rLYDI";
export var zIndex90 = "index-module--z-index-90--2kvy3";
export var zIndex100 = "index-module--z-index-100--GziqC";
export var link = "index-module--link--2l7yl";
export var uppercase = "index-module--uppercase--2i5df";
export var more = "index-module--more--2eAoY";
export var button = "index-module--button--3EF8I";
export var send = "index-module--send--3wL_5";
export var sm = "index-module--sm--2N6Tr";
export var mainPage = "index-module--mainPage--3-Mbm";
export var designWithUs = "index-module--designWithUs--187RG";
export var productsSlider = "index-module--productsSlider--137df";
export var cooperation = "index-module--cooperation--1A0Na";
export var articleSlider = "index-module--articleSlider--MvRGq";