// extracted by mini-css-extract-plugin
export var mt0 = "ProductsSlider-module--mt-0--2Tjzc";
export var pt0 = "ProductsSlider-module--pt-0--5rVCS";
export var mb0 = "ProductsSlider-module--mb-0--1csST";
export var pb0 = "ProductsSlider-module--pb-0--2-oEh";
export var ml0 = "ProductsSlider-module--ml-0--k_iX7";
export var pl0 = "ProductsSlider-module--pl-0--1JnRo";
export var mr0 = "ProductsSlider-module--mr-0--1MJuz";
export var pr0 = "ProductsSlider-module--pr-0--1Nc4V";
export var mt5 = "ProductsSlider-module--mt-5--hjnpH";
export var pt5 = "ProductsSlider-module--pt-5--1oODv";
export var mb5 = "ProductsSlider-module--mb-5--3lObr";
export var pb5 = "ProductsSlider-module--pb-5--1GXLA";
export var ml5 = "ProductsSlider-module--ml-5--2VaCe";
export var pl5 = "ProductsSlider-module--pl-5--m4-6N";
export var mr5 = "ProductsSlider-module--mr-5--Fonib";
export var pr5 = "ProductsSlider-module--pr-5--2Yw2q";
export var mt10 = "ProductsSlider-module--mt-10--2bmC4";
export var pt10 = "ProductsSlider-module--pt-10--1iKVJ";
export var mb10 = "ProductsSlider-module--mb-10--2bjZj";
export var pb10 = "ProductsSlider-module--pb-10--96uNX";
export var ml10 = "ProductsSlider-module--ml-10--T4mcf";
export var pl10 = "ProductsSlider-module--pl-10--1EvwX";
export var mr10 = "ProductsSlider-module--mr-10--3qP-r";
export var pr10 = "ProductsSlider-module--pr-10---P-kk";
export var mt15 = "ProductsSlider-module--mt-15--10sQL";
export var pt15 = "ProductsSlider-module--pt-15--2vi8l";
export var mb15 = "ProductsSlider-module--mb-15--3ab6i";
export var pb15 = "ProductsSlider-module--pb-15--3TTAs";
export var ml15 = "ProductsSlider-module--ml-15--28Qig";
export var pl15 = "ProductsSlider-module--pl-15--1hbVa";
export var mr15 = "ProductsSlider-module--mr-15--FJMi-";
export var pr15 = "ProductsSlider-module--pr-15--1okxW";
export var mt20 = "ProductsSlider-module--mt-20--zCpp-";
export var pt20 = "ProductsSlider-module--pt-20--1OtPI";
export var mb20 = "ProductsSlider-module--mb-20--w75Zp";
export var pb20 = "ProductsSlider-module--pb-20--1DJy6";
export var ml20 = "ProductsSlider-module--ml-20--QDMMH";
export var pl20 = "ProductsSlider-module--pl-20--JYiU_";
export var mr20 = "ProductsSlider-module--mr-20--1eHBz";
export var pr20 = "ProductsSlider-module--pr-20--UByib";
export var mt25 = "ProductsSlider-module--mt-25--1OAEd";
export var pt25 = "ProductsSlider-module--pt-25--2cHdS";
export var mb25 = "ProductsSlider-module--mb-25--20FJ1";
export var pb25 = "ProductsSlider-module--pb-25--2JoYn";
export var ml25 = "ProductsSlider-module--ml-25--7zPZO";
export var pl25 = "ProductsSlider-module--pl-25--pSKHv";
export var mr25 = "ProductsSlider-module--mr-25--oR47c";
export var pr25 = "ProductsSlider-module--pr-25--3BLbN";
export var mt30 = "ProductsSlider-module--mt-30--16DvD";
export var pt30 = "ProductsSlider-module--pt-30--6Rt6Q";
export var mb30 = "ProductsSlider-module--mb-30--2Rom5";
export var pb30 = "ProductsSlider-module--pb-30--1aVKS";
export var ml30 = "ProductsSlider-module--ml-30--gqVLi";
export var pl30 = "ProductsSlider-module--pl-30--3vZuK";
export var mr30 = "ProductsSlider-module--mr-30--2-Sep";
export var pr30 = "ProductsSlider-module--pr-30--3UvjT";
export var mt35 = "ProductsSlider-module--mt-35--3sGsF";
export var pt35 = "ProductsSlider-module--pt-35--12kz2";
export var mb35 = "ProductsSlider-module--mb-35--3GF_b";
export var pb35 = "ProductsSlider-module--pb-35--3fylc";
export var ml35 = "ProductsSlider-module--ml-35--1F39x";
export var pl35 = "ProductsSlider-module--pl-35--3DIj-";
export var mr35 = "ProductsSlider-module--mr-35--1CIs9";
export var pr35 = "ProductsSlider-module--pr-35--1WsGe";
export var mt40 = "ProductsSlider-module--mt-40--3mU7b";
export var pt40 = "ProductsSlider-module--pt-40--1NVac";
export var mb40 = "ProductsSlider-module--mb-40--3_tWP";
export var pb40 = "ProductsSlider-module--pb-40--2LtyZ";
export var ml40 = "ProductsSlider-module--ml-40--TCy2S";
export var pl40 = "ProductsSlider-module--pl-40--2xhSc";
export var mr40 = "ProductsSlider-module--mr-40--3EuB4";
export var pr40 = "ProductsSlider-module--pr-40--izawo";
export var mt45 = "ProductsSlider-module--mt-45--VBD3V";
export var pt45 = "ProductsSlider-module--pt-45--yE2b4";
export var mb45 = "ProductsSlider-module--mb-45--3fnrG";
export var pb45 = "ProductsSlider-module--pb-45--iYoMa";
export var ml45 = "ProductsSlider-module--ml-45--1Cbe0";
export var pl45 = "ProductsSlider-module--pl-45--2ve5Q";
export var mr45 = "ProductsSlider-module--mr-45--2Ye1N";
export var pr45 = "ProductsSlider-module--pr-45--FpRrb";
export var mt50 = "ProductsSlider-module--mt-50--22zGh";
export var pt50 = "ProductsSlider-module--pt-50--aC6la";
export var mb50 = "ProductsSlider-module--mb-50--3X8Em";
export var pb50 = "ProductsSlider-module--pb-50--3-y6F";
export var ml50 = "ProductsSlider-module--ml-50--1YFZ1";
export var pl50 = "ProductsSlider-module--pl-50--h9vOh";
export var mr50 = "ProductsSlider-module--mr-50--2Qwvh";
export var pr50 = "ProductsSlider-module--pr-50--2iUNZ";
export var mt75 = "ProductsSlider-module--mt-75--22XCw";
export var pt75 = "ProductsSlider-module--pt-75--pXSeG";
export var mb75 = "ProductsSlider-module--mb-75--2aQjM";
export var pb75 = "ProductsSlider-module--pb-75--Cg4Wb";
export var ml75 = "ProductsSlider-module--ml-75--26HqR";
export var pl75 = "ProductsSlider-module--pl-75--2_haR";
export var mr75 = "ProductsSlider-module--mr-75--5i6Il";
export var pr75 = "ProductsSlider-module--pr-75--6Esq_";
export var mt100 = "ProductsSlider-module--mt-100--3bziw";
export var pt100 = "ProductsSlider-module--pt-100--1Ci7v";
export var mb100 = "ProductsSlider-module--mb-100--3qsv5";
export var pb100 = "ProductsSlider-module--pb-100--2e1Vs";
export var ml100 = "ProductsSlider-module--ml-100--1ZD0c";
export var pl100 = "ProductsSlider-module--pl-100--1i9A7";
export var mr100 = "ProductsSlider-module--mr-100--2bVzf";
export var pr100 = "ProductsSlider-module--pr-100--nzhLZ";
export var zIndex1 = "ProductsSlider-module--z-index-1--25VtM";
export var zIndex2 = "ProductsSlider-module--z-index-2--3zo7-";
export var zIndex3 = "ProductsSlider-module--z-index-3--3073K";
export var zIndex4 = "ProductsSlider-module--z-index-4--1w0FW";
export var zIndex5 = "ProductsSlider-module--z-index-5--1RVBf";
export var zIndex6 = "ProductsSlider-module--z-index-6--1sYz-";
export var zIndex7 = "ProductsSlider-module--z-index-7--3g81R";
export var zIndex8 = "ProductsSlider-module--z-index-8--2zrjr";
export var zIndex9 = "ProductsSlider-module--z-index-9--3Jy5E";
export var zIndex10 = "ProductsSlider-module--z-index-10--27LgY";
export var zIndex20 = "ProductsSlider-module--z-index-20--37fBu";
export var zIndex30 = "ProductsSlider-module--z-index-30--1RahO";
export var zIndex40 = "ProductsSlider-module--z-index-40--aFOn9";
export var zIndex50 = "ProductsSlider-module--z-index-50--2Obbz";
export var zIndex60 = "ProductsSlider-module--z-index-60--33dw7";
export var zIndex70 = "ProductsSlider-module--z-index-70--2nFhu";
export var zIndex80 = "ProductsSlider-module--z-index-80--2zU0b";
export var zIndex90 = "ProductsSlider-module--z-index-90--3TIIm";
export var zIndex100 = "ProductsSlider-module--z-index-100--ZvHM6";
export var link = "ProductsSlider-module--link--3-kvl";
export var uppercase = "ProductsSlider-module--uppercase--2y0QK";
export var more = "ProductsSlider-module--more--Bt93q";
export var button = "ProductsSlider-module--button--3vgwP";
export var send = "ProductsSlider-module--send--17-Hq";
export var sm = "ProductsSlider-module--sm--26IZu";
export var productsSliderWrap = "ProductsSlider-module--productsSliderWrap--1rAnx";
export var sectionShadowTitle = "ProductsSlider-module--sectionShadowTitle--2Ej0Q";
export var sliderWrap = "ProductsSlider-module--sliderWrap--3XuU-";
export var swiperNavigation = "ProductsSlider-module--swiperNavigation--2WHu3";
export var prev = "ProductsSlider-module--prev--1byPQ";
export var next = "ProductsSlider-module--next--1i4sU";